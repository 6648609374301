import * as React from "react"
import { Fragment } from 'react'
import { Link } from "gatsby"
/* import { graphql } from 'gatsby' */
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Fade } from "react-awesome-reveal"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons'


const IndexPage = () => {
	function handleClickWhatsapp() {
		window.gtag("event", "conversion", { send_to: ["AW-312158706/"]})
	}
	function handleClickCall() {
		window.gtag("event", "conversion", { send_to: ["AW-312158706/"]})
	}
	return (
    <>
      <div id='home' className="w-full bg-white overflow-hidden">
				<div className='bg-primary'>
					<div className='max-w-screen-lg mx-auto container px-4'>
						<div className='flex flex-col md:flex-row md:grid md:grid-cols-2 sm:grid-cols-1 py-8'>
							<div className='flex flex-col items-center justify-center logo'>
								<div className='flex flex-col'>
									<div className='flex justify-center md:justify-start mb-0 md:mb-1'>
										<StaticImage src="../images/logo.png" height={137} width={137} layout="fixed" alt="logo"/>
									</div>
									<div className=''>
										<StaticImage src="../images/nevaskinproduct.png" height={250} layout="fixed" alt="logo"/>
									</div>
								</div>
							</div>
							<div className='flex items-center mt-12 md:mt-0 mb-8 md:mb-0'>
								<div className='flex flex-col'>
									<h1 className='text-2xl md:text-3xl font-bold text-center md:text-left mb-2'>Kulit Sehat dan Cerah Dengan Nevaskin</h1>

									<p className='text-center md:text-left'>Kulit putih dan bersih impian semua wanita<br/>Kulit tubuh gelap, belang, dan banyak bekas luka apalagi strechmark membuat kita menjadi insecure atau tidak merasa percaya diri. Gimana engga, hampir 70% orang menilai diri kita dari tampilan luar<br/>Eitsss, tenang dulu. Bukan berarti ga ada solusinya<br/>Dapatkan kulit sehat bersih dan putih dengan Bodycare Nevaskin</p>
								</div>
							</div>
						</div>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#fee2e2" />
					</svg>
				</div>

				<div id='layanan' className='bg-red-100 w-full flex flex-col bg-main'>
					<div className='max-w-screen-lg mx-auto container mx-auto mb-8'>
						<div className='flex justify-center my-10'>
							<h2 className='text-2xl md:text-3xl font-semibold text-center text-black'>Kamu Punya Masalah Kulit Apa?</h2>
						</div>
						<div className='flex justify-center items-center'>
							<StaticImage src="../images/masalahkulit.png" alt="logo"/>
						</div>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#b5dce1" />
					</svg>
				</div>

				<div className='bg-primary'>
					<div className='max-w-screen-sm mx-auto container mx-auto text-center px-8 py-8'>
						<div id='review' className='flex flex-col'>
							<div className='flex flex-col items-center justify-center mb-4'>
								<StaticImage src="../images/icon-review.png" width={100} layout="fixed" alt="logo"/>
								<h2 className='text-2xl md:text-3xl font-semibold text-center'>REVIEW TENTANG BODYCARE NEVASKIN</h2>
							</div>
							<div className='youtube-video-container mb-4'>
								<iframe width="560" height="315" src="https://www.youtube.com/embed/uyDsvmDvg2c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
							<div className='youtube-video-container mb-4'>
								
								<iframe width="100%" height="100%" src="https://www.youtube.com/embed/N6_G9cy3qMs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
							<div className='youtube-video-container mb-4'>
								<iframe width="560" height="315" src="https://www.youtube.com/embed/5E6nkOqIMR8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
						</div>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#fee2e2" />
					</svg>
				</div>

				<div className='bg-red-100'>
					<div className='max-w-screen-sm mx-auto container mx-auto text-center py-8'>
						<div id='testimoni' className='flex flex-col'>
							<div className='flex flex-col items-center justify-center mb-8'>
								<StaticImage src="../images/icon-testi.png" width={75} layout="fixed" alt="logo"/>
								<h2 className='text-2xl md:text-3xl font-semibold text-center my-4'>Testimoni</h2>
							</div>
						</div>
						<div className='flex flex-col w-full mx-auto items-center'>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-1.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-2.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-3.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-4.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-5.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className='mb-4'>
								<StaticImage src="../images/testimoni-6.png" height={450} layout="fixed" alt="testimony"/>
							</div>
							<div className=''>
								<StaticImage src="../images/testimoni-7.png" height={450} layout="fixed" alt="testimony"/>
							</div>
						</div>
						<div className='mt-6 text-center'>
							<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-primary hover:bg-primary text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
						</div>
						<div className='mt-4 text-center'>
							<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-primary hover:bg-primary text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
						</div>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#b5dce1" />
					</svg>
				</div>
					
				<div className='bg-primary'>
					<div className='max-w-screen-sm mx-auto container mx-auto text-center px-8 py-8'>
						<div id='product' className='flex flex-col my-10 mx-auto items-center'>
							<div className='mb-8'>
								<StaticImage src="../images/products.png" height={300} layout="fixed" alt="logo"/>
							</div>
							<div className='flex flex-col mb-8'>
								<h2 className='text-2xl md:text-3xl font-semibold text-center'>Kandungan Dan Manfaatnya</h2>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-8'>Body Whitening Serum</h3>
								<div className='flex flex-col text-left md:text-center'>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Niacinamide</h4>
										<p>Dapat mencerahkan kulit,melindungi kulit dari radikal bebas,menghilangkan noda hitam, mengurangi garis halus/strechmark</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Betaglucan</h4>
										<p>Selain melembabkan,betaglucan juga bisa meningkatkan system imun kulit,serta mempercepat proses penyembuhan luka</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Bisabolol</h4>
										<p>Melindungi kulit dari kerusaka,mendukung proses penyembuhan kulit,menjaga kelembaban kulit & menjaga terjadinya kerusakan kulit</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Titanum Dioxide</h4>
										<p>Melindungi kulit dari terpaparnya sinar matahari,cocok untuk kulit sensitive & tidak menimbulkan bekas putih pada kulit</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Glyceryl Stearate</h4>
										<p>Merawat kulit kering,melembabkan kulit,membuat kulit terlihat cerah & menghilangkan bekas luka</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Caprylyl Glycol</h4>
										<p>Dapat meningkatkan aktivitas penetrasi kulit,berguna untuk melembabkan kulit. Serta membantu untuk merawat kulit bersisik/kering</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Tocopheryl Acetate</h4>
										<p>Meningkatkan antioksidan pada kulit,melembabkan kulit,menyejukan kulit serta mempunyai sifat menenangkan kulit</p>
									</div>
								</div>
							</div>
							<div className='flex flex-col'>
								<h2 className='text-2xl md:text-3xl font-semibold text-center'>Kandungan Dan Manfaatnya</h2>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-8'>Peeling Spray</h3>
								<div className='flex flex-col text-left md:text-center'>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Niacinamide</h4>
										<p>Dapat mencerahkan kulit,melindungi kulit dari radikal bebas,menghilangkan noda hitam, mengurangi garis halus/strechmark</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Betaglucan</h4>
										<p>Selain melembabkan,betaglucan juga bisa meningkatkan system imun kulit,serta mempercepat proses penyembuhan luka</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Collagen</h4>
										<p>Mengencangkan kulit,mencegah penuaan dini,mengurangi garis halus/strechmark,meningkatkan elastilitas kulit & melembutkan kulit</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Glutathione</h4>
										<p>Melawan radikal bebas,melawan pembentukan melanin sebagai pemberi warna kulit,menghambat eumelanin (zat yang menyebabkan kulit menjadi gelap) & memelihara kesehatan kulit</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Ethyl Ascorbit Acid</h4>
										<p>Memiliki efek memutihkan kulit,mencegah sintesis melanin,menghambat pertumbuhan bakteri,memperbaiki sel-sel kulit & meningkatkan elastilitas kulit</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Caprylyl Glycol</h4>
										<p>Dapat meningkatkan aktivitas penetrasi kulit,berguna untuk melembabkan kulit. Serta membantu untuk merawat kulit bersisik/kering</p>
									</div>
									<div className='bg-white card shadow-sm p-6 m-4 rounded-lg mb-2'>
										<h4 className='text-lg font-semibold'>Butylene Glycol</h4>
										<p>Membantu mengondisikan kulit dan melembabkan kulit</p>
									</div>
									<div className='mt-6 text-center'>
										<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
									</div>
									<div className='mt-4 text-center'>
										<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
									</div>
								</div>
							</div>

						</div>
	
					</div>
					<svg viewBox="0 -15 700 110" width="100%" height="110" preserveAspectRatio="none">
						<path transform="translate(0, -15)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#fee2e2" />
					</svg>
				</div>

				<div className='bg-red-100'>
					<div className='max-w-screen-sm mx-auto container mx-auto text-center px-8 py-8'>
						<div className='flex flex-col items-center justify-center mb-8'>
							<StaticImage src="../images/icon-kandungan.png" width={75} layout="fixed" alt="logo"/>
							<h2 className='text-2xl md:text-3xl font-semibold text-center my-4'>Cek Dulu Keamanannya</h2>
							<p className='text-center'>Eitss bentar dulu dong,sebelum kamu memilih perawatan wajib banget mastiin keamanannya yah, ingat diri kamu berharga & jangan asal pilih. Pastikan terdaftar di BPOM dan produk halal</p>
						</div>
						<div className='flex flex-col w-full mx-auto items-stretch'>
							<div className='flex flex-col flex-1 items-center mb-8'>
								<StaticImage src="../images/bpom.png" height={150} layout="fixed" alt="logo"/>
								<h4 className='text-lg font-semibold mt-2'>Terdaftar di BPOM</h4>
								<p>BPOM adalah lembaga yang bertugas dibidang pengawasan obat dan makanan. Bisa dipastikan semua produk Nevaskin terdaftar di BPOM. Kalian bisa cek langsung di website resmi BPOM RI</p>
							</div>
							<div className='flex flex-col flex-1 items-center mb-8'>
								<StaticImage src="../images/halal.png" height={150} layout="fixed" alt="logo"/>
								<h4 className='text-lg font-semibold mt-2'>Terdaftar MUI HALAL</h4>
								<p>Tenang Nevaskin Lovers,produk Nevaskin sudah terdaftar MUI Halal pastinya komposisi dan bahan aktif kita halal ya</p>
							</div>
							<div className='flex flex-col flex-1 items-center mb-8'>
								<StaticImage src="../images/aman-bumil-busui.png" height={150} layout="fixed" alt="logo"/>
								<h4 className='text-lg font-semibold mt-2'>Aman Untuk Bumil & Busui ga sih? </h4>
								<p>Pastikan produk yang kamu pakai aman dipakai ibu hamil & menyusui karena kalian harus ekstra lebih hati-hati dalam memilih produk. Terus aman ngga sih Nevaskin aman buat ibu hamil & menyusui? tenang produk Nevaskin aman digunakan ibu hamil & menyusui. Dan peeling spray juga aman digunakan anak-anak mulai dari umur 5 tahun </p>
							</div>
							<div className='flex flex-col flex-1 items-center mb-8'>
								<StaticImage src="../images/tanpa-merkuri.png" height={150} layout="fixed" alt="logo"/>
								<h4 className='text-lg font-semibold mt-2'>Tanpa Mercury</h4>
								<p>Buat kalian yang paham skincare/bodycare pasti kalian ngga asing banget sama yang namanya MERKURI,yaps merkuri merupakan zat bersifat korosif yang membuat lapisan kulit menipis dan dapat menyebabkan kerusakan syaraf dan ginjal. Nah pastikan bodycare kamu tidak mengandung MERKURI ya, dan tentunya bodycare Nevaskin tidak mengandung MERKURI </p>
							</div>
							<div className='flex flex-col flex-1 items-center mb-8'>
								<StaticImage src="../images/hg.png" height={150} layout="fixed" alt="logo"/>
								<h4 className='text-lg font-semibold mt-2'>Tanpa Hydroquinone</h4>
								<p>Hydroquinone juga zat yang berbahaya membuat kulit kemerahan dan strechmark,tentunya Nevaskin tidak menggunakan HYDROQUINONE!</p>
							</div>
						</div>
						<div className='mt-6 text-center'>
							<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-primary hover:bg-primary text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
						</div>
						<div className='mt-4 text-center'>
							<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-primary hover:bg-primary text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
						</div>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#b5dce1" />
					</svg>
				</div>

				
				<div className='bg-primary'>
					<div className='max-w-screen-sm flex flex-col mx-auto container mx-auto text-center px-8 py-8'>
						<div id='paket' className='flex flex-col'>
							<div className='flex flex-col justify-center'>
								<h2 className='text-2xl md:text-3xl font-semibold text-center mb-2'>PAKET BODYCARE NEVASKIN</h2>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-1'>(Peeling Spray & Body Whitening Serum)</h3>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-8'>Rp. 180.000</h3>
							</div>
						</div>
						<div className='flex flex-col items-center justify-center mt-4 mb-8'>
							<StaticImage src="../images/paket-bodycare.png" height={450} layout="fixed" alt="paket-bodycare"/>
							<p className='mt-4'>Rekomendasi banget buat kamu yang mau order Nevaskin, wajib banget order 1 paket karena hasilnya akan lebih maksimal. Perpaduan keduanya saling memenuhi kebutuhan kulitmu.</p>
						</div>
						<div className='mt-6 text-center'>
							<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
						</div>
						<div className='mt-4 mb-6 text-center'>
							<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
						</div>

						<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
							<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
							<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#b5dce1" />
						</svg>

						<div id='paket' className='flex flex-col'>
							<div className='flex flex-col justify-center'>
								<h2 className='text-2xl md:text-3xl font-semibold text-center mb-2'>BODY WHITENING SERUM</h2>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-8'>Rp. 90.000</h3>
							</div>
						</div>
						<div className='flex flex-col items-center justify-center mt-4 mb-8'>
							<StaticImage src="../images/body-whitening.png" height={450} layout="fixed" alt="body-whitening"/>
						</div>

						<div className='mt-6 text-center'>
							<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
						</div>
						<div className='mt-4 mb-6 text-center'>
							<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
						</div>

						<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
							<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
							<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#b5dce1" />
						</svg>

						<div id='paket' className='flex flex-col'>
							<div className='flex flex-col justify-center'>
								<h2 className='text-2xl md:text-3xl font-semibold text-center mb-2'>PEELING SPRAY</h2>
								<h3 className='text-xl md:text-2xl font-semibold text-center mb-8'>Rp. 90.000</h3>
							</div>
						</div>
						<div className='flex flex-col items-center justify-center mt-4 mb-8'>
							<StaticImage src="../images/peeling-spray.png" height={450} layout="fixed" alt="peeling-spray"/>
						</div>

						<div className='mt-6 text-center'>
							<a href='https://wa.me/6287879635148/?text=Hallo%20kak%20%2C%20saya%20tertarik%20dengan%20produk%20nevaskin.'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faWhatsapp} /> Dapatkan melalui Whatsapp Kami. Klik Disini!!!</button></a>
						</div>
						<div className='mt-4 mb-6 text-center'>
							<a href='https://shopee.co.id/nevaskinofficial?smtt=0.0.9'><button class="w-4/5 p-2 pl-5 pr-5 transition-colors duration-700 transform bg-red-300 hover:bg-red-400 text-black text-lg rounded-lg focus:border-4 border-green-300"><FontAwesomeIcon icon={faShoppingBag} /> Dapatkan melalui Shopee Kami. Klik Disini!!!</button></a>
						</div>
						<p>Hati-hati produk PALSU dapatkan yang asli hanya di NEVASKIN OFFICIAL</p>
					</div>
					<svg viewBox="0 -20 700 110" width="100%" height="50" preserveAspectRatio="none">
						<path transform="translate(0, -20)" d="M0,10 c80,-22 240,0 350,18 c90,17 260,7.5 350,-20 v50 h-700" fill="#fbdbdb" />
						<path d="M0,10 c80,-18 230,-12 350,7 c80,13 260,17 350,-5 v100 h-700z" fill="#fee2e2" />
					</svg>
				</div>
					
				
				
				<div className='bg-red-100 footer text-center text-sm'>
					<p className='text-gray-800 py-3'>&copy; 2021 <a href="https://www.nevaskinofficial.com">Nevaskinofficial.com</a> All Right Reserved<br/>Developed by <a className='text-red-400' href="https://www.kodemerah.com" target='_blank' rel="noreferrer">KodeMerah</a></p>
				</div>
			</div>
    </>
  )
}

export default IndexPage
